.brandBg {
  position: absolute;
  left: 0;
  right: 0;
  z-index: -100;
  height: 1000px;
  overflow: hidden;
}

.bg1 {
  background: radial-gradient(55.47% 104% at 100% 0%, #003279 0%, #00204e 100%);
}

.bg2 {
  position: absolute;
  width: 20vw;
  height: 430px;
  left: -2.3vw;
  top: 47px;
  background: url("./svg/buildingblocks.svg") no-repeat;
  background-size: contain;
}

.bg3 {
  position: absolute;
  top: 111px;
  right: 6vw;
  width: 12.75vw;
  height: 380px;
  background: url("./svg/dots.svg") no-repeat;
  background-size: contain;
}

.bg4 {
  position: absolute;
  width: 209px;
  height: 209px;
  right: -83px;
  top: 756px;
  background: #f93549;
}
