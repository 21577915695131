.outcomeImage {
  width: 100%;
  background-image: url("../../../public/assets/desktop-outcome.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 60% bottom;
}

.outcomeHeader {
  width: 100%;
  background: radial-gradient(55.47% 104% at 100% 0%, #003279 0%, #00204e 100%);
}

a.outcomeLink {
  /* $fg.interactive.default */
  color: #2f60f1;
  text-decoration: underline;
}

a.outcomeLink:visited {
  /* $fg.interactive.visited */
  color: #4345ac;
}

a.outcomeLink:active {
  /* $fg.interactive.active */
  color: #163590;
}

ol.outcomeList {
  margin-inline-start: 2em;
  margin-top: 0.5rem;
}

@media screen and (max-width: 904px) {
  .outcomeHeader {
    width: 100%;
    background-image: linear-gradient(to bottom, transparent 70%, rgba(0, 0, 0, 0.8)),
      url("../../../public/assets/mobile-outcome.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  }

  .outcomeImage {
    background: transparent;
  }
}

@media screen and (min-width: 1900px) and (max-width: 2000px) {
  .outcomeImage {
    background-position: 60% 90%;
  }
}

@media screen and (min-width: 2001px) {
  .outcomeImage {
    background-position: center;
  }
}
