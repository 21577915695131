.privacy a:hover {
  text-decoration: underline;
}

.privacyHeader {
  width: 100%;
  background: radial-gradient(55.47% 104% at 100% 0%, #003279 0%, #00204e 100%);
}

.privacyImage {
  background-image: url("../../../public/assets/desktop-privacy.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 904px) {
  .privacyHeader {
    background-image: linear-gradient( to bottom, transparent 70%, rgba(255,255,255, 0.7)), url("../../../public/assets/mobile-privacy.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top
  }

  .privacyImage {
    background: white;
  }
}

@media screen and (min-width: 2000px) {
  .privacyImage {
    background-position: center !important;
  }
}